import React from "react"

import "./style.scss"

import SectionWrapper from "../../components/SectionWrapper"

function Testimonials({ testimonialsSection }) {
  return (
    <SectionWrapper name={testimonialsSection.name}>
      {testimonialsSection.testimonials.map((testimonial, i) => (
        <blockquote className="testimonials-layout" key={i}>
          <p>"{testimonial.quote}"</p>
          <cite>{testimonial.source}</cite>
        </blockquote>
      ))}
    </SectionWrapper>
  )
}

export default Testimonials
