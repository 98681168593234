import React from "react"
import "./style.scss"

function Hero({ isFontLoaded, heroOpacity }) {
  return (
    <section
      className="hero-section"
      style={{ opacity: heroOpacity }}
      role="contentinfo"
      aria-label="Hero for Daniela Gerstmann's portfolio website"
    >
      <h1 className={`${isFontLoaded && "hero-animation"}`}>
        Daniela Gerstmann
      </h1>
      <p className={`${isFontLoaded && "hero-animation"}`}>
        creative producing & arts marketing
      </p>
      <div
        className={`hero-line-div hero-line-div__large ${
          isFontLoaded && "hero-animation"
        }`}
      />
      <div
        className={`hero-line-div__small-media hero-line-div ${
          isFontLoaded && "hero-animation"
        }`}
      />
    </section>
  )
}

export default Hero
