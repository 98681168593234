import React, { useState, useRef, useEffect } from "react"
import { Link, graphql } from "gatsby"

import "./style.scss"

// Components
import Layout from "../components/layout/index"
import SEOHelmet from "../components/SEOHelmet/index"
import Divider from "../components/Divider"

// Sections
import Hero from "../sections/Hero"
import About from "../sections/About"
import Services from "../sections/Services"
import Clients from "../sections/Clients"
import Portfolio from "../sections/Portfolio"
import Cv from "../sections/Cv"
import Testimonials from "../sections/Testimonial"
import Contact from "../sections/Contact"

const sanityProjectId = "duswzy06"

const sanityConfig = {
  projectId: sanityProjectId,
  dataset: "production",
}

const IndexPage = ({ data }) => {
  const portfolioSection = data.sanitySectionPortfolio
  const portfolioProjects = portfolioSection.projects.filter(
    project => project.publishToggle === true
  )

  const serializers = {
    types: {
      code: props => (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      ),
    },
    marks: {
      internalLink: ({ mark, children }) => {
        const projectIndex = portfolioProjects.findIndex(
          p => p._id === mark.reference._ref
        )
        const href = `/portfolio/${portfolioProjects[projectIndex]?.slug?.current}`
        return <Link to={href}>{children}</Link>
      },
      link: ({ mark, children }) => {
        const { blank, href } = mark
        return blank ? (
          <a href={href} target="_blank" rel="noreferrer">
            {children}
          </a>
        ) : (
          <a href={href}>{children}</a>
        )
      },
    },
  }

  const [fontLoaded, setFontLoaded] = useState(false)
  const [height, setHeight] = useState(0)
  const [isAnimatedOne, setAnimatedOne] = useState(false)
  const [isAnimatedTwo, setAnimatedTwo] = useState(false)
  const [isAnimatedThree, setAnimatedThree] = useState(false)
  const [isAnimatedFour, setAnimatedFour] = useState(false)
  const [isAnimatedFive, setAnimatedFive] = useState(false)
  const [isAnimatedSix, setAnimatedSix] = useState(false)
  const refOne = useRef(null)
  const refTwo = useRef(null)
  const refThree = useRef(null)
  const refFour = useRef(null)
  const refFive = useRef(null)
  const refSix = useRef(null)

  const handleScroll = () => {
    if (height) {
      if (
        refOne.current &&
        refOne.current.getBoundingClientRect().bottom <= height
      ) {
        setAnimatedOne(true)
      }
      if (
        refTwo.current &&
        refTwo.current.getBoundingClientRect().bottom <= height
      ) {
        setAnimatedTwo(true)
      }
      if (
        refThree.current &&
        refThree.current.getBoundingClientRect().bottom <= height
      ) {
        setAnimatedThree(true)
      }
      if (
        refFour.current &&
        refFour.current.getBoundingClientRect().bottom <= height
      ) {
        setAnimatedFour(true)
      }
      if (
        refFive.current &&
        refFive.current.getBoundingClientRect().bottom <= height
      ) {
        setAnimatedFive(true)
      }
      if (
        refSix.current &&
        refSix.current.getBoundingClientRect().bottom <= height
      ) {
        setAnimatedSix(true)
      }
    }
  }

  useEffect(() => {
    setHeight(window.innerHeight - 60)

    if ("fonts" in document) {
      if (document.fonts.check("12px Header")) {
        setFontLoaded(true)
      } else {
        document.fonts.load("12px Header").then(() => setFontLoaded(true))
      }
    } else {
      setTimeout(() => setFontLoaded(true), 1000)
    }
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", () => handleScroll)
    }
  }, [height])

  return (
    <Layout>
      {heroOpacity => (
        <>
          <SEOHelmet title="Daniela Gerstmann" />

          <Hero isFontLoaded={fontLoaded} heroOpacity={heroOpacity} />

          <About
            aboutSection={data?.sanitySectionAbout}
            serializers={serializers}
            sanityConfig={sanityConfig}
          />

          <Divider isAnimated={isAnimatedOne} ref={refOne} />

          <Services servicesSection={data?.sanitySectionServices} />

          <Divider isAnimated={isAnimatedTwo} ref={refTwo} />

          <Clients clientsSection={data?.sanitySectionClients} />

          <Divider isAnimated={isAnimatedThree} ref={refThree} />

          <Portfolio
            portfolioSection={portfolioSection}
            portfolioProjects={portfolioProjects}
            sanityConfig={sanityConfig}
          />

          <Divider isAnimated={isAnimatedFour} ref={refFour} />

          <Cv cvSection={data?.sanitySectionCv} />

          <Divider isAnimated={isAnimatedFive} ref={refFive} />

          <Testimonials testimonialsSection={data?.sanitySectionTestimonials} />

          <Divider isAnimated={isAnimatedSix} ref={refSix} />

          <Contact contactSection={data.sanitySectionContact} />
        </>
      )}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomeQuery {
    sanitySectionAbout {
      name
      alt
      _rawBody
      image {
        asset {
          _id
        }
      }
    }

    sanitySectionServices {
      name
      languagesIntro
      languages
      location
      services {
        name
      }
    }

    sanitySectionClients {
      name
      currentIntro
      currentClients {
        name
        weblink
      }
      formerIntro
      formerClients {
        name
        weblink
      }
    }

    sanitySectionPortfolio(projects: {}) {
      name
      projects {
        _id
        name
        publishToggle
        slug {
          current
        }
        description
        image {
          asset {
            _id
            url
          }
        }
        alt
      }
    }

    sanitySectionCv {
      name
      education {
        school
        thesis
        title
        year
      }
    }

    sanitySectionTestimonials {
      name
      testimonials {
        quote
        source
      }
    }

    sanitySectionContact {
      name
      text
      email
    }
  }
`
