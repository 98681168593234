import React from "react"

import "./style.scss"

import SectionWrapper from "../../components/SectionWrapper"

const ClientListItems = ({ clientArray }) => {
  return clientArray.map((client, i, arr) => {
    const currentClient = client.weblink ? (
      <a href={client.weblink} target="_blank" rel="noreferrer">
        {client.name}
      </a>
    ) : (
      client.name
    )
    if (arr.length - 1 === i) {
      return <li key={i}>{currentClient}</li>
    } else {
      return (
        <li key={i} className="li-with-space">
          {currentClient},
        </li>
      )
    }
  })
}

function Clients({ clientsSection }) {
  return (
    <SectionWrapper name={clientsSection.name}>
      <div className="clients-div">
        <h3>{clientsSection.currentIntro}</h3>
        <ul>
          <ClientListItems clientArray={clientsSection.currentClients} />
        </ul>
        <h3>{clientsSection.formerIntro}</h3>
        <ul>
          <ClientListItems clientArray={clientsSection.formerClients} />
        </ul>
      </div>
    </SectionWrapper>
  )
}

export default Clients
