import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { getGatsbyImageData } from "gatsby-source-sanity"
import BlockContent from "@sanity/block-content-to-react"

import "./style.scss"

import SectionWrapper from "../../components/SectionWrapper"

function About({ aboutSection, sanityConfig, serializers }) {
  const aboutImageAssetId = aboutSection?.image?.asset._id
  const aboutImageData = getGatsbyImageData(aboutImageAssetId, {}, sanityConfig)

  return (
    <SectionWrapper name={aboutSection.name}>
      <div className="about-div">
        <article>
          <BlockContent
            blocks={aboutSection._rawBody}
            serializers={serializers}
          />
        </article>
        <GatsbyImage
          image={aboutImageData}
          alt={aboutSection.alt}
          className="gatsby-about-img"
        />
      </div>
    </SectionWrapper>
  )
}

export default About
