import React, { forwardRef } from "react"

import "./style.scss"

const Divider = forwardRef(function Divider(props, ref) {
  const { isAnimated } = props

  return (
    <div className={`divider-line ${isAnimated ? "animated" : ""}`} ref={ref} />
  )
})

export default Divider
