import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { getGatsbyImageData } from "gatsby-source-sanity"

import "./style.scss"

import SectionWrapper from "../../components/SectionWrapper"

function Portfolio({ portfolioSection, portfolioProjects, sanityConfig }) {
  return (
    <SectionWrapper name={portfolioSection.name}>
      <div className="portfolio-div portfolio-div-padding">
        {portfolioProjects.map((project, i) => {
          const ProjectImageAssetId = project.image.asset._id
          const projectImageData = getGatsbyImageData(
            ProjectImageAssetId,
            { maxWidth: 300, maxHeight: 300 },
            sanityConfig
          )

          return (
            <article className="portfolio-div-item" key={i}>
              <Link to={`/portfolio/${project.slug.current}/`}>
                <GatsbyImage
                  image={projectImageData}
                  alt={project.alt}
                  className="gatsby-portfolio-img"
                />
                <div className="portfolio-text">
                  <h3>{project.name}</h3>
                  <p>{project.description}</p>
                </div>
              </Link>
            </article>
          )
        })}
      </div>
    </SectionWrapper>
  )
}

export default Portfolio
