import React from "react"

import "./style.scss"

import SectionWrapper from "../../components/SectionWrapper"

function Contact({ contactSection }) {
  return (
    <SectionWrapper name={contactSection.name}>
      <div className="contact-div">
        <p>{contactSection.text}</p>
        <a href={`mailto:${contactSection.email}`}>
          <p className="contact-email">{contactSection.email}</p>
        </a>
      </div>
    </SectionWrapper>
  )
}

export default Contact
