import React from "react"

import "./style.scss"

function SectionWrapper({ name, children }) {
  const headerId = `${name}-section-header`
  return (
    <section
      role="contentinfo"
      className="section-wrapper"
      id={name}
      aria-labelledby={headerId}
    >
      <h2 id={headerId}>{name}</h2>
      {children}
    </section>
  )
}

export default SectionWrapper
