import React, { useEffect, useRef, useState } from "react"
import Header from "../Header/index"
import Footer from "../Footer/index"
import CookieConsentBanner from "../CookieConsentBanner/index"
import "../../style"
import "./style.scss"

const Layout = ({ children }) => {
  const [isSticky, setSticky] = useState(false)
  const [logoOpacity, setLogoOpacity] = useState(0)
  const [heroOpacity, setHeroOpacity] = useState(1)

  const ref = useRef(null)

  const handleScroll = () => {
    const startScrollValue = window.innerHeight - 85 - 111 - 150
    const yValue = window.scrollY - startScrollValue
    const endScrollValue = 150
    const opacityValue = yValue / endScrollValue
    let limitedOpacityValue = Math.min(Math.max(opacityValue, 0), 1)

    setLogoOpacity(limitedOpacityValue)
    setHeroOpacity(1 - limitedOpacityValue)

    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= 0)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", () => handleScroll)
    }
  }, [])

  return (
    <>
      <div className={`sticky-wrapper${isSticky ? " sticky" : ""}`} ref={ref}>
        <Header opacity={logoOpacity} />
      </div>
      <div
        style={{
          margin: `0 auto`,
          zIndex: 2,
        }}
      >
        <main> {children(heroOpacity)}</main>
      </div>
      <Footer />
      <CookieConsentBanner />
    </>
  )
}

export default Layout
