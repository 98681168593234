import React from "react"

import "./style.scss"

import SectionWrapper from "../../components/SectionWrapper"

function ServiceList({ services, className }) {
  return (
    <ul className={className}>
      {services.map((service, i) => (
        <li key={i}>
          <p>{service.name}</p>
        </li>
      ))}
    </ul>
  )
}

function Services({ servicesSection }) {
  const servicesList = servicesSection?.services
  console.log({ servicesList })
  // Half list
  const half = Math.ceil(servicesList?.length / 2)
  const firstServicesHalf = servicesList?.slice(0, half)
  const secondServicesHalf = servicesList?.slice(-half)

  // Third list
  const third = Math.ceil(servicesList?.length / 3)
  const firstServicesThird = servicesList?.slice(0, third)
  const secondServicesThird = servicesList?.slice(third, third * 2)
  const thirdServicesThird = servicesList?.slice(
    third * 2,
    servicesList?.length
  )

  return (
    <SectionWrapper name={servicesSection.name}>
      {/* WIDE SCREEN */}
      <div className="services-list services-list-div__large-media">
        <ServiceList services={firstServicesThird} className="padded-list " />
        <ServiceList services={secondServicesThird} className="padded-list " />
        <ServiceList services={thirdServicesThird} />
      </div>

      {/* MEDIUM SCREEN */}
      <div className="services-list services-list-div__medium-media">
        <ServiceList services={firstServicesHalf} className="padded-list " />
        <ServiceList services={secondServicesHalf} />
      </div>

      {/* SMALL SCREEN */}
      <div className="services-list-div__small-media">
        <ServiceList services={servicesList} />
      </div>

      <div className="services-p-div">
        <p>{servicesSection.languagesIntro}</p>
        <p className="languages-p">{servicesSection.languages}</p>
        <p>{servicesSection.location}</p>
      </div>
    </SectionWrapper>
  )
}

export default Services
