import React from "react"

import "./style.scss"

import SectionWrapper from "../../components/SectionWrapper"

function Cv({ cvSection }) {
  return (
    <SectionWrapper name={cvSection.name}>
      {cvSection.education.map((e, i) => {
        const isLastEntry = i === cvSection.education.length - 1

        return (
          <div className="cv-layout" key={i}>
            <h3 className="cv-year">{e.year}</h3>

            <div className={`cv-details-div ${isLastEntry && "last-entry"}`}>
              <p className="title-p">{e.title}</p>
              <p className="school-p">{e.school}</p>
              {e.thesis && (
                <p>
                  Thesis: <i>{e.thesis}</i>
                </p>
              )}
            </div>
          </div>
        )
      })}
    </SectionWrapper>
  )
}

export default Cv
